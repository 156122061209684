import styled from "styled-components";
import backSectionThree from "../../assets/images/bg-three.png";

export const Section = styled.section`
  background: url(${backSectionThree}) no-repeat;
  background-size: cover;
  background-position: 0 100%;
  padding: 6rem 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  padding-left: 10rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;
  h1 {
    color: #fff;
    font-size: 50px;
    margin-bottom: 20px;
  }
  span {
    color: #ffffff80;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1400px) {
    padding-left: 4rem;
  }
`;

export const RightSide = styled.div`
  position: relative;
  padding-right: 12rem;
  img {
    position: absolute;
    bottom: -95px;
    right: 10rem;
    @media only screen and (max-width: 1400px) {
      right: 4rem;
    }
  }
`;

export const ListItensContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  color: #fff;
  margin-bottom: 50px;
  li {
    margin: 10px 5px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
