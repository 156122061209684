import React from "react";
import GlobalStyle from "./styles/global.css";
import Header from "./components/Header";
import SectionOne from "./views/SectionOne";
import SectionTwo from "./views/SectionTwo";
import SectionThree from "./views/SectionThree";
import SectionFour from "./views/SectionFour";
import SectionFive from "./views/SectionFive";
import Footer from "./components/Footer";
import SubFooter from "./components/SubFooter";

const App = () => (
  <>
    <Header />
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
    <SectionFive />
    <Footer />
    <SubFooter />
    <GlobalStyle />
  </>
);

export default App;
