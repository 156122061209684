import React, { useState } from "react";

import { FiMapPin, FiPhone } from "react-icons/fi";
import swal from "sweetalert";
import { Input, Textarea } from "../../components/Input/styles.css";
import {
  Section,
  Container,
  LeftSide,
  RightSide,
  FormContainer,
  NameEmailContainer,
  StyledButton
} from "./styles.css";
// import api from "../../services/api";

export default function SectionFive() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [assunto, setAssunto] = useState("");
  const submitForm = () => {
    if (!name || !email || !assunto || !message) {
      return swal(
        "Aviso!",
        "Preencha todos os campos para enviar a mensagem!",
        "warning"
      );
    }
    setName("");
    setEmail("");
    setMessage("");
    setAssunto("");
    return swal(
      "Mensagem enviada com sucesso!",
      "Obrigado por contribuir com sua opinião!",
      "success"
    );
  };

  // const submitForm = async () => {
  //   try {
  //     if (!name || !email || !assunto || !message) {
  //       return swal(
  //         "Aviso!",
  //         "Preencha todos os campos para enviar a mensagem!",
  //         "warning"
  //       );
  //     }
  //     const obj = {
  //       name,
  //       email,
  //       phone: "(41)99961-5258",
  //       title: assunto,
  //       body: message
  //     };
  //     await api.post("/messages", obj);
  //     return swal(
  //       "Mensagem enviada com sucesso!",
  //       "Obrigado por contribuir com sua opinião!",
  //       "success"
  //     );
  //   } catch (error) {
  //     console.log(JSON.stringify(error));
  //     return swal("Erro!", "Erro ao enviar a mensagem!", "error");
  //   }
  // };

  return (
    <Section id="sec-five">
      <Container>
        <LeftSide>
          <h1>FICOU COM ALGUMA DÚVIDA?</h1>
          <span>
            Queremos saber o que achou do nosso produto! <br />
            Dê a sua opinião e mais importante, <b>
              não fique com dúvidas
            </b>, <br />
            se abra conosco e te retornaremos o quanto antes :)
          </span>
          <ul>
            <li>
              <FiMapPin color="#E70289B3" size={25} />
              R. Marechal Deodoro, 857
              <br />
              CJ-1705, Centro de Curitiba – PR, <br />
              80060-010
            </li>
            <li>
              <FiPhone color="#E70289B3" size={25} />
              (41) 99506-0080
            </li>
          </ul>
        </LeftSide>
        <RightSide>
          <FormContainer>
            <h1>ENTRE EM CONTATO</h1>
            <NameEmailContainer>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Nome"
                width="48%"
              />
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                width="48%"
              />
            </NameEmailContainer>
            <Input
              value={assunto}
              onChange={e => setAssunto(e.target.value)}
              placeholder="Assunto"
              width="100%"
            />
            <Textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              placeholder="Mensagem"
              width="100%"
              rows="6"
            />
            <span> </span>
            <StyledButton
              width="120px"
              background="#E70289"
              onClick={() => submitForm()}
            >
              Enviar
            </StyledButton>
          </FormContainer>
        </RightSide>
      </Container>
    </Section>
  );
}
