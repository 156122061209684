import React from "react";

import { Section, Container } from "./styles.css";

export default function SubFooter() {
  return (
    <Section>
      <Container>
        <span>
          Indicamos aos usuários a lerem atenciosamente aos{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://furafilla.com.br/termos-e-condiçoes-de-uso.html"
          >
            termos de uso
          </a>{" "}
          e a{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://furafilla.com.br/politica-privacidade.html"
          >
            política de privacidade
          </a>
          .
        </span>
        <span>FuraFilla | 35.252.464/0001-70</span>
      </Container>
    </Section>
  );
}
