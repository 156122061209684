import React from "react";

import { Container, CardHeader, CardBody } from "./styles.css";

export default function Card({ image, title, desc }) {
  return (
    <Container>
      <CardHeader>
        <img width={150} src={image} alt="card-1" />
      </CardHeader>
      <CardBody>
        <h2>{title}</h2>
        <span>{desc}</span>
      </CardBody>
    </Container>
  );
}
