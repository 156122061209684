import React, { useState, useEffect, useCallback } from "react";

import { Link } from "react-scroll";

import Logo from "../../assets/images/furafilla_logo.png";

import { Nav, Container, LogoLink, LinkContainer } from "./styles.css";

export default function Header() {
  const [scroll, setScroll] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  // const [menuOpened, setMenuOpened] = useState(false);

  const handleScroll = useCallback(() => {
    setScroll(document.documentElement.scrollTop || document.body.scrollTop);
    if (scroll > 30) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [scroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }, [handleScroll]);

  return (
    <header>
      <Nav isScrolled={scrolled}>
        <Container>
          <Link to="sec-one" smooth offset={-70} duration={500}>
            <LogoLink href="/">
              <img src={Logo} alt="" />
            </LogoLink>
          </Link>
          <LinkContainer>
            <Link to="sec-two" smooth offset={-70} duration={500}>
              <span>Como funciona</span>
            </Link>
            <Link to="sec-three" smooth offset={-70} duration={500}>
              <span>Vantagens</span>
            </Link>
            <Link to="sec-four" smooth offset={-70} duration={500}>
              <span>Quem somos</span>
            </Link>
            <Link to="sec-five" smooth offset={-70} duration={500}>
              <span>Contato</span>
            </Link>
          </LinkContainer>
        </Container>
      </Nav>
    </header>
  );
}
