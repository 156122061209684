import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Syncopate:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html, body, #root {
    height: 100%;
    width: fit-content;
    scroll-snap-align: center;
  }
  h1 {
    font-family:'Syncopate', sans-serif !important;
  }
  body {
    font: 16px 'Open Sans', sans-serif;
    background: #F6F6F6;
    color: #0C0C0C;
    -webkit-font-smoothing: antialiased !important;
  }
  ul {
    list-style: none;
  }
`;
