import styled from "styled-components";

export const Section = styled.section`
  background: #e70289;
  padding: 1rem 0;
`;

export const Container = styled.div`
  padding: 0 7rem 0 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 10px;
  }
  a {
    color: #362b70;
  }
`;
