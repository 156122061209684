import styled from "styled-components";

export const Input = styled.input`
  background: #F8F8F8;
  width: ${props => props.width}
  margin: 10px 0 10px 0;
  height: 35px;
  padding: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
`;

export const Textarea = styled.textarea`
  background: #F8F8F8;
  width: ${props => props.width}
  margin: 10px 0 10px 0;
  padding: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
`;
