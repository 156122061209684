import React from "react";

import { FiWatch, FiLock, FiCheck, FiClock, FiMove } from "react-icons/fi";

import { FaRegHandPeace } from "react-icons/fa";
import {
  Section,
  Container,
  LeftSide,
  RightSide,
  ListItensContainer,
  ButtonsContainer
} from "./styles.css";
import Button from "../../components/Button/styles.css";
import iphone from "../../assets/images/iPhoneX.png";
import store from "../../assets/images/appstore.png";
import play from "../../assets/images/android.png";

export default function SectionThree() {
  return (
    <Section id="sec-three">
      <Container>
        <LeftSide>
          <h1>POR QUE ESCOLHER O FURAFILLA?</h1>
          <span>
            O FuraFilla é o aplicativo que vai trocar seu tempo perdido em filas
            por tempo de qualidade, e te garantimos isso com:
          </span>
          <ListItensContainer>
            <ul>
              <li>
                <FiWatch color="#E70289" size={20} />
                Agilidade
              </li>
              <li>
                <FiLock color="#E70289" size={20} />
                Segurança
              </li>
              <li>
                <FiMove color="#E70289" size={20} />
                Diversidade
              </li>
            </ul>
            <ul>
              <li>
                <FiCheck color="#E70289" size={20} />
                Objetividade
              </li>
              <li>
                <FiClock color="#E70289" size={20} />
                Tempo
              </li>
              <li>
                <FaRegHandPeace color="#E70289" size={20} />
                Praticidade
              </li>
            </ul>
          </ListItensContainer>
          <ButtonsContainer>
            <Button width="120px" background="#000000">
              <img width={100} src={store} alt="store" />
            </Button>
            <Button width="120px" background="#000000">
              <img width={100} src={play} alt="store" />
            </Button>
          </ButtonsContainer>
        </LeftSide>
        <RightSide>
          <img src={iphone} alt="" />
        </RightSide>
      </Container>
    </Section>
  );
}
