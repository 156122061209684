import styled from "styled-components";

const Button = styled.button`
  height: 35px;
  background: ${props => props.background};
  width: ${props => props.width};
  border-radius: 15px;
  border: none;
  color: #fff;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`;

export default Button;
