import React from "react";

import { Link } from "react-scroll";
import Button from "../../components/Button/styles.css";
import { Section, Container, LeftSide, ButtonsContainer } from "./styles.css";
import girl from "../../assets/images/girl-section-one.png";
import store from "../../assets/images/appstore.png";
import play from "../../assets/images/android.png";

export default function SectionOne() {
  return (
    <Section id="sec-one">
      <Container>
        <LeftSide>
          <h1>FILAS NUNCA MAIS!</h1>
          <span>
            Ainda perde tempo em filas enormes? <br />
            Com apenas alguns cliques <br />
            você poderá furar todas elas!
          </span>
          <ButtonsContainer>
            <Link to="sec-two" smooth offset={-70} duration={500}>
              <Button width="120px" background="#E70289">
                <b>SAIBA MAIS</b>
              </Button>
            </Link>
            <Button width="120px" background="#000000">
              <img width={100} src={store} alt="store" />
            </Button>
            <Button width="120px" background="#000000">
              <img width={100} src={play} alt="store" />
            </Button>
          </ButtonsContainer>
        </LeftSide>
        <img className="girl-image" src={girl} alt="Logo" />
      </Container>
    </Section>
  );
}
