import styled from "styled-components";

export const Container = styled.div`
  width: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
  padding: 50px;
  -webkit-transition: transform 0.5s linear;
  transition: transform 0.5s linear;
  cursor: pointer;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const CardBody = styled.div`
  h2 {
    margin-bottom: 20px;
    color: #131415;
  }
  span {
    color: #13141566;
  }
`;
