import styled from "styled-components";
import backSectionFive from "../../assets/images/bg-five.png";
import Button from "../../components/Button/styles.css";

export const Section = styled.section`
  background: url(${backSectionFive}) no-repeat;
  background-size: cover;
  background-position: 0 100%;
  padding: 6rem 0;
`;

export const Container = styled.div`
  padding: 0 10rem;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1400px) {
    padding: 0 4rem;
  }
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;
  h1 {
    align-self: center;
    color: #fff;
    font-size: 50px;
    margin-bottom: 20px;
  }
  span {
    color: #ffffff66;
    margin-bottom: 10px;
  }
  li {
    color: #fff;
    margin: 30px 7px;
    display: flex;
    flex-direction: row;
    svg {
      margin-right: 10px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 440px;
  height: 465px;
  background: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
  padding: 50px 50px 0px 50px;
  border-radius: 9px;
  h1 {
    margin-bottom: 30px;
    font-size: 35px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    color: #00c853;
    margin: 20px 0;
    font-size: 10px;
  }
`;

export const NameEmailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-bottom: -15px;
`;
