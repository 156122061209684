import styled from "styled-components";
import backSectionOne from "../../assets/images/back-one.png";

export const Section = styled.section`
  background: url(${backSectionOne}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    align-self: flex-end;
  }
`;

export const LeftSide = styled.div`
  padding: 0 10rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;

  h1 {
    z-index: 2;
    color: #ffffff;
    font-size: 120px;
    word-spacing: 100vw;
  }
  span {
    z-index: 2;
    margin: 20px 0 20px 0;
    color: #ffffff66;
    padding-left: 10px;
  }
  @media only screen and (max-width: 1400px) {
    padding: 0 4rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  z-index: 2;
  img {
    align-self: center !important;
  }
`;
