import styled from "styled-components";
import backSectionTwo from "../../assets/images/back-two.png";

export const Section = styled.section`
  background: url(${backSectionTwo}) no-repeat;
  background-size: cover;
  background-position: 0 100%;
  padding: 10rem 0;
`;

export const Container = styled.div`
  padding: 0 10rem;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1400px) {
    padding: 0 4rem;
  }
`;
export const CardContainer = styled.div`
  position: relative;
  margin-left: 16rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;
  h1 {
    align-self: center;
    font-size: 50px;
    word-spacing: 100vw;
    margin-bottom: 30px;
    color: #131415;
  }
  h2 {
    color: #13141566;
    font-weight: bold;
    margin-bottom: 15px;
  }
  span {
    color: #13141566;
    margin-bottom: 30px;
  }
`;
