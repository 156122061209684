import React from "react";

import { Link } from "react-scroll";
import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import Logo from "../../assets/images/furafilla_logo.png";
import { Section, Container, IconContainer, LinkContainer } from "./styles.css";

export default function Footer() {
  return (
    <Section>
      <Container>
        <Link to="sec-one" smooth offset={-70} duration={500}>
          <img src={Logo} alt="" />
        </Link>
        <LinkContainer>
          <Link to="sec-two" smooth offset={-70} duration={500}>
            <span>Como funciona</span>
          </Link>
          <Link to="sec-three" smooth offset={-70} duration={500}>
            <span>Vantagens</span>
          </Link>
          <Link to="sec-four" smooth offset={-70} duration={500}>
            <span>Quem somos</span>
          </Link>
          <Link to="sec-five" smooth offset={-70} duration={500}>
            <span>Contato</span>
          </Link>
        </LinkContainer>
        <IconContainer>
          <FaFacebookSquare color="#fff" size="2em" />
          <FaInstagram color="#fff" size="2em" />
          <FaLinkedin color="#fff" size="2em" />
        </IconContainer>
      </Container>
    </Section>
  );
}
