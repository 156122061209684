import styled from "styled-components";

export const Nav = styled.nav`
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 10rem;
  position: fixed !important;
  flex-flow: row nowrap !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: ${props => (props.isScrolled ? "#362B70" : "transparent")};
  box-shadow: ${props =>
    props.isScrolled ? "0 2px 3px rgba(0,0,0,.08)" : "none"};
  @media only screen and (max-width: 1400px) {
    padding: 0.5rem 4rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
`;

export const LogoLink = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  width: 7.5rem;
  line-height: inherit;
  white-space: nowrap;
  cursor: pointer;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #fff;
    cursor: pointer;
    padding: 15px;
    &: hover {
      font-weight: bold;
    }
  }
`;
