import React from "react";

import { Section, Container, RightSide } from "./styles.css";
import city from "../../assets/images/city.png";

export default function SectionFour() {
  return (
    <Section id="sec-four">
      <Container>
        <img src={city} alt="city" />
        <RightSide>
          <h1>QUEM SOMOS</h1>
          <span>
            Somos uma startup curitibana que veio para revolucionar a maneira de
            realizar pedidos. Com uma equipe de 5 especialistas desenvolvemos um
            aplicativo que irá mudar de forma disruptiva a maneira a qual você
            enfrentará as suas próximas "filas".
          </span>
        </RightSide>
      </Container>
    </Section>
  );
}
