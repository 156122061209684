import styled from "styled-components";

export const Section = styled.section`
  background: #131416;
  padding: 3rem 0;
`;

export const Container = styled.div`
  padding: 0 7rem 0 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 30px;
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    margin: 10px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  span {
    color: #fff;
    cursor: pointer;
    padding: 15px;
    &: hover {
      color: #e70289;
    }
  }
`;
