import React from "react";

import { Link } from "react-scroll";
import ReactCardCarousel from "react-card-carousel";
import { Section, Container, CardContainer, RightSide } from "./styles.css";
import Card from "../../components/Card";
import Button from "../../components/Button/styles.css";
import cardOneImage from "../../assets/images/cellphone-buy.png";
import cardTwoImage from "../../assets/images/cook.png";
import cardThreeImage from "../../assets/images/box-delivery.png";

export default function SectionTwo() {
  return (
    <Section id="sec-two">
      <Container>
        <CardContainer>
          <ReactCardCarousel autoplay autoplay_speed={5000}>
            <Card
              image={cardOneImage}
              title="1 - Compre pelo seu celular!"
              desc="O Fura Filla permite realizar compras em restaurantes, bares e lanchonetes, tudo pelo seu celular, dando adeus às filas!"
            />
            <Card
              image={cardTwoImage}
              title="2 - Estabelecimento prepara seu pedido!"
              desc="No Fura Fila, os estabelecimentos terão os pedidos em tempo real para praparar seu pedido o mais rápido possível!"
            />
            <Card
              image={cardThreeImage}
              title="3 - É só ir buscar!"
              desc="Com o Fura Fila, você só precisa ir para o estabelecimento quando seu pedido estiver pronto pra você!"
            />
          </ReactCardCarousel>
        </CardContainer>
        <RightSide>
          <h1>COMO FUNCIONA?</h1>
          <h2>É só clicar e ir buscar.</h2>
          <span>
            Abra o aplicativo do Fura Fila, selecione o estabelecimento e tudo o
            que você deseja deste local, confirme seu pedido e é só isso. <br />
            Quando estiver pronto você será notificado, é só ir buscar!
          </span>
          <Link to="sec-three" smooth offset={-70} duration={500}>
            <Button width="120px" background="#362B70">
              <b>SAIBA MAIS</b>
            </Button>
          </Link>
        </RightSide>
      </Container>
    </Section>
  );
}
